@keyframes taking-damage {
  0% { opacity: 1 }
  10% { opacity: 0 }
  50% { opacity: 1 }
  60% { opacity: 0 }
}

.taking-damage {
  animation: taking-damage .1s 1 alternate;
}

@keyframes attacking {
  0% { transform: none }
  100% { transform: translate(16px, -16px) rotate(12deg); }
}

@keyframes attacking-down {
  0% { transform: none }
  100% { transform: translate(-16px, 16px) rotate(-12deg); }
}

.attacking {
  animation: attacking .1s 1 alternate;
}

.attacking.attack-direction-down {
  animation: attacking-down .1s 1 alternate;
}

@keyframes returning-to-pokeball {
  20% {
    filter:
      invert(100%)
      sepia(100%)
      saturate(0%)
      hue-rotate(184deg)
      brightness(1000%)
      contrast(103%);
    transform: none;
  }
  30% { transform: scale(1, 1); }
  75% { transform: scale(.3, .3); }
  98% { transform: scale(.3, .3); }
  100% {
    filter:
      invert(100%)
      sepia(100%)
      saturate(0%)
      hue-rotate(184deg)
      brightness(1000%)
      contrast(103%);
    transform: scale(0, 0);
  }
}

.returning-to-pokeball {
  animation: returning-to-pokeball 1s 1 forwards;
}

.fainted {
  visibility: hidden;
}
